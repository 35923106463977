<template>
  <a-modal
    width="350px"
    class="survey-type-modal"
    :dialog-style="{ display: 'none' }"
    :footer="null"
    :closable="false"
    :visible="active">
    <h3 class="flex align-center txt-32 txt-bold">
      {{ $t('components.titles.setSurveyType') }}
    </h3>
    <p>
      {{ $t('components.description.setSurveyType') }}
    </p>
    <a-select v-model="type" class="w100"
      :get-popup-container="(triggerNode) => triggerNode.parentNode">
      <a-icon slot="suffixIcon" type="caret-down"/>
      <a-select-option value="Full Smart">
        {{ $t('components.dropdown.fullSmart') }}
      </a-select-option>
      <a-select-option value="NNS">
        {{ $t('components.dropdown.nns') }}
      </a-select-option>
      <a-select-option value="Rapid Smart">
        {{ $t('components.dropdown.rapidSmart') }}
      </a-select-option>
      <a-select-option value="SENS">
        {{ $t('components.dropdown.sens') }}
      </a-select-option>
      <a-select-option value="Other">
        {{ $t('components.dropdown.other') }}
      </a-select-option>
    </a-select>
    <div class="save-actions flex justify-space-between w100">
      <a-button
        class="mt-24 w100"
        type="primary"
        size="large"
        :disabled="!type"
        @click="save">
        {{ $t('values.save') }}
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'SurveyTypeModal',
  props: {
    metadata: {
      type: Object,
      default: () => ({})
    },
    onComplete: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      type: undefined,
      active: false
    };
  },
  created() {
    if (!this.metadata.surveyType) {
      this.active = true;
    }
  },
  methods: {
    ...mapActions([
      'setMetadata'
    ]),
    save() {
      this.setMetadata({
        ...this.metadata,
        surveyType: this.type
      });
      this.active = false;
      window.localStorage.setItem('surveyTypeWasSetByModal', true);
      this.onComplete();
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
